var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "form_masuk"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center mb-2 mb-sm-0",
    attrs: {
      "sm": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _c('span', [_vm._v("Kembali")])], 1)], 1)], 1), _c('form-wizard', {
    ref: "wizard",
    staticClass: "vertical-steps steps-transparent mb-4",
    attrs: {
      "color": "#7367F0",
      "title": "Form Type 3",
      "subtitle": _vm.formTitle,
      "layout": "vertical",
      "finish-button-text": _vm.$router.currentRoute.params.id !== undefined ? 'Simpan Revisi' : 'Terbitkan Form',
      "back-button-text": "Previous",
      "lazy": true,
      "before-change": function beforeChange() {
        return true;
      }
    },
    on: {
      "on-complete": _vm.formSubmitted,
      "on-change": _vm.onTabChange
    }
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.$t('Delivery Routes'),
      "icon": "feather icon-user",
      "lazy": true,
      "before-change": _vm.validationFormDeliveryRoute
    }
  }, [_c('validation-observer', {
    ref: "deliveryRouteRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('Delivery Routes')) + " ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(this.$t('Create Your ')) + _vm._s(_vm.$t('Delivery Routes')))])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "py-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-form', {
    ref: "formSegmen",
    staticClass: "repeater-form ml-2 mr-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Segmen",
      "label-for": "vi-schedule-segmen"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Segmen",
      "vid": "vi-schedule-segmen",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-segmen",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "reduce": function reduce(option) {
              return option.title;
            },
            "label": "title",
            "options": _vm.option.segmen
          },
          model: {
            value: _vm.form.schedule.segmen,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "segmen", $$v);
            },
            expression: "form.schedule.segmen"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.form.schedule.segmen ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "6",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "py-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-form', {
    ref: "formBongkar",
    staticClass: "repeater-form ml-2 mr-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kegiatan",
      "label-for": "vi-schedule-kegiatan-delivery"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    model: {
      value: _vm.form.schedule.keg_bongkar,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "keg_bongkar", $$v);
      },
      expression: "form.schedule.keg_bongkar"
    }
  }, [_vm._v(" Bongkar ")])], 1)], 1), _vm.form.schedule.keg_bongkar ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No Dokumen",
      "label-for": "vi-bongkar-no_document"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "No Dokumen",
      "vid": "vi-bongkar-no_document",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-bongkar-no_document",
            "placeholder": "No Dokumen"
          },
          model: {
            value: _vm.form.schedule.bongkar_no_doc,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "bongkar_no_doc", $$v);
            },
            expression: "form.schedule.bongkar_no_doc"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3033467579)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_bongkar ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Barang",
      "label-for": "vi-bongkar-asal_brg"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Barang",
      "vid": "vi-bongkar-asal_brg",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-bongkar-asal_brg",
            "placeholder": "Asal Barang"
          },
          model: {
            value: _vm.form.schedule.bongkar_asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "bongkar_asal", $$v);
            },
            expression: "form.schedule.bongkar_asal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1111985665)
  })], 1)], 1) : _vm._e(), _vm._l(_vm.form.schedule.itemBongkars, function (item, index) {
    return _vm.form.schedule.keg_bongkar ? _c('b-row', {
      key: item.fe_id,
      ref: "rowBongkar",
      refInFor: true,
      staticClass: "repeater-form ml-1 mr-1",
      attrs: {
        "id": item.fe_id
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      staticClass: "mt-0",
      attrs: {
        "pill": "",
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1), _c('b-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('hr')]), _c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "danger"
      }
    }, [_c('feather-icon', {
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          return _vm.removeItemBongkar(index);
        }
      }
    })], 1)], 1), _vm.form.schedule.keg_bongkar ? _c('b-col', {
      attrs: {
        "lg": "12",
        "md": "12",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jenis Barang",
        "label-for": "vi-bongkar-jenis_brg"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jenis Barang",
        "vid": "vi-bongkar-jenis_brg",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var errors = _ref4.errors;
          return [_c('v-select', {
            attrs: {
              "id": "vi-bongkar-jenis_brg",
              "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
              "reduce": function reduce(option) {
                return option.title;
              },
              "label": "title",
              "options": _vm.option.jenis_brg
            },
            model: {
              value: item.jenis_brg,
              callback: function callback($$v) {
                _vm.$set(item, "jenis_brg", $$v);
              },
              expression: "item.jenis_brg"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_bongkar ? _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jumlah Muatan",
        "label-for": "vi-bongkar-jml_muatan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jumlah Muatan",
        "vid": "vi-bongkar-jml_muatan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var errors = _ref5.errors;
          return [_c('cleave', {
            staticClass: "form-control",
            attrs: {
              "id": "vi-bongkar-jml_muatan",
              "raw": true,
              "options": _vm.options.number,
              "placeholder": "Cth 1.000 (dot automaticaly )"
            },
            model: {
              value: item.jml_muatan,
              callback: function callback($$v) {
                _vm.$set(item, "jml_muatan", $$v);
              },
              expression: "item.jml_muatan"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_bongkar ? _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Satuan",
        "label-for": "vi-bongkar-satuan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Satuan",
        "vid": "vi-bongkar-satuan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var errors = _ref6.errors;
          return [_c('v-select', {
            attrs: {
              "id": "vi-bongkar-satuan",
              "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
              "reduce": function reduce(option) {
                return option.title;
              },
              "label": "title",
              "options": _vm.option.satuan
            },
            model: {
              value: item.satuan,
              callback: function callback($$v) {
                _vm.$set(item, "satuan", $$v);
              },
              expression: "item.satuan"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1) : _vm._e();
  }), _vm.form.schedule.keg_bongkar ? _c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "md",
      "variant": "outline-primary",
      "block": ""
    },
    on: {
      "click": _vm.repeateAgainBongkar
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah Jenis Barang")])], 1)], 1) : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _vm.form.schedule.segmen ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "6",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "py-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-form', {
    ref: "formMuat",
    staticClass: "repeater-form ml-2 mr-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kegiatan",
      "label-for": "vi-schedule-kegiatan-delivery"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    model: {
      value: _vm.form.schedule.keg_muat,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "keg_muat", $$v);
      },
      expression: "form.schedule.keg_muat"
    }
  }, [_vm._v(" Muat ")])], 1)], 1), _vm.form.schedule.keg_muat ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No Dokumen",
      "label-for": "vi-muat-no_document"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-muat-no_document",
      "placeholder": "No Dokumen ( Opsional )"
    },
    model: {
      value: _vm.form.schedule.muat_no_doc,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "muat_no_doc", $$v);
      },
      expression: "form.schedule.muat_no_doc"
    }
  })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_muat ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan",
      "label-for": "vi-muat-tujuan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan",
      "vid": "vi-muat-tujuan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-muat-tujuan",
            "placeholder": "Tujuan"
          },
          model: {
            value: _vm.form.schedule.muat_tujuan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "muat_tujuan", $$v);
            },
            expression: "form.schedule.muat_tujuan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3639729822)
  })], 1)], 1) : _vm._e(), _vm._l(_vm.form.schedule.itemMuats, function (item, index) {
    return _vm.form.schedule.keg_muat ? _c('b-row', {
      key: item.fe_id,
      ref: "rowMuat",
      refInFor: true,
      staticClass: "repeater-form ml-1 mr-1",
      attrs: {
        "id": item.fe_id
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      staticClass: "mt-0",
      attrs: {
        "pill": "",
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1), _c('b-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('hr')]), _c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "danger"
      }
    }, [_c('feather-icon', {
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          return _vm.removeItemMuat(index);
        }
      }
    })], 1)], 1), _vm.form.schedule.keg_muat ? _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jenis Barang",
        "label-for": "vi-muat-jenis_brg"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jenis Barang",
        "vid": "vi-muat-jenis_brg",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref8) {
          var errors = _ref8.errors;
          return [_c('v-select', {
            attrs: {
              "id": "vi-muat-jenis_brg",
              "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
              "reduce": function reduce(option) {
                return option.title;
              },
              "label": "title",
              "options": _vm.option.jenis_brg
            },
            model: {
              value: item.jenis_brg,
              callback: function callback($$v) {
                _vm.$set(item, "jenis_brg", $$v);
              },
              expression: "item.jenis_brg"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_muat ? _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jumlah Muatan",
        "label-for": "vi-muat-jml_muatan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jumlah Muatan",
        "vid": "vi-muat-jml_muatan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref9) {
          var errors = _ref9.errors;
          return [_c('cleave', {
            staticClass: "form-control",
            attrs: {
              "id": "vi-muat-jml_muatan",
              "raw": true,
              "options": _vm.options.number,
              "placeholder": "Cth 1.000 (dot automaticaly )"
            },
            model: {
              value: item.jml_muatan,
              callback: function callback($$v) {
                _vm.$set(item, "jml_muatan", $$v);
              },
              expression: "item.jml_muatan"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e(), _vm.form.schedule.keg_muat ? _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Satuan",
        "label-for": "vi-muat-satuan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Satuan",
        "vid": "vi-muat-satuan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref10) {
          var errors = _ref10.errors;
          return [_c('v-select', {
            attrs: {
              "id": "vi-muat-satuan",
              "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
              "reduce": function reduce(option) {
                return option.title;
              },
              "label": "title",
              "options": _vm.option.satuan
            },
            model: {
              value: item.satuan,
              callback: function callback($$v) {
                _vm.$set(item, "satuan", $$v);
              },
              expression: "item.satuan"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1) : _vm._e();
  }), _vm.form.schedule.keg_muat ? _c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "md",
      "variant": "outline-primary",
      "block": ""
    },
    on: {
      "click": _vm.repeateAgainMuat
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah Jenis Barang")])], 1)], 1) : _vm._e()], 2)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('Land Vehicles'),
      "icon": "feather icon-truck",
      "lazy": true,
      "before-change": _vm.validationFormVehicle
    }
  }, [_c('validation-observer', {
    ref: "vehicleRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(this.$t('Land Vehicles')) + " "), _c('b-badge', {
    attrs: {
      "href": "#",
      "pill": "",
      "variant": _vm.statusSuggestionColor[_vm.dm_land_vehicleState]
    }
  }, [_c('strong', [_c('span', {
    domProps: {
      "textContent": _vm._s(_vm.statusSuggestion[_vm.dm_land_vehicleState])
    }
  })])])], 1), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(this.$t('Create Your ')) + _vm._s(this.$t('Land Vehicles')) + _vm._s(this.$t(' or Use data on already exist')) + ". ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Plat Nomor Polisi",
      "label-for": "vi-dm_land_vehicle-plat_no_pol"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Plat Nomor Polisi",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_land_vehicle-plat_no_pol",
            "suggestions": [{
              data: _vm.form.dm_land_vehicle.dm_land_vehicles
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'KT 1945 DR'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_land_vehicle', 'plat_no_pol');
            },
            "input": function input($event) {
              return _vm.searchForm($event, 'dm_land_vehicle/', 'dm_land_vehicle', 'plat_no_pol');
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var suggestion = _ref12.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item",
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.plat_no_pol || '', ", Jenis: ").concat(suggestion.item.type_vehicle || ''))
                }
              })])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_land_vehicle.plat_no_pol,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_land_vehicle, "plat_no_pol", $$v);
            },
            expression: "form.dm_land_vehicle.plat_no_pol"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_land_vehicleState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_land_vehicleState]))])])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.dm_land_vehicleStateLoading ? _c('b-spinner', {
          staticClass: "suggest-loading",
          attrs: {
            "small": "",
            "variant": "primary"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kendaraan",
      "label-for": "vi-dm_land_vehicle-type_vehicle"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jenis Kendaraan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-dm_land_vehicle-type_vehicle",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "label": "title",
            "reduce": function reduce(option) {
              return option.title;
            },
            "disabled": !_vm.form.dm_land_vehicle.isNew,
            "options": _vm.option.kendaraan,
            "readonly": _vm.$router.currentRoute.params.id !== undefined ? false : !_vm.form.dm_land_vehicle.isNew
          },
          model: {
            value: _vm.form.dm_land_vehicle.type_vehicle,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_land_vehicle, "type_vehicle", $$v);
            },
            expression: "form.dm_land_vehicle.type_vehicle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Created BY",
      "label-for": "vi-dm_land_vehicle-created_by"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-dm_land_vehicle-created_by",
      "placeholder": "Otomatis Terisi",
      "value": _vm.form.dm_land_vehicle.user.full_name,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone of Created BY",
      "label-for": "vi-dm_land_vehicle-phone_of_created_by"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-dm_land_vehicle-phone_of_created_by",
      "placeholder": "Otomatis Terisi",
      "value": _vm.form.dm_land_vehicle.user.phone,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Created AT",
      "label-for": "vi-dm_land_vehicle-created_at"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-dm_land_vehicle-created_at",
      "placeholder": "Otomatis Terisi",
      "value": _vm.dateFormat(_vm.form.dm_land_vehicle.created_at),
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Updated AT",
      "label-for": "updated_at"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "updated_at",
      "type": "text",
      "readonly": "",
      "value": _vm.dateFormat(_vm.form.dm_land_vehicle.updated_at),
      "placeholder": "otomatis terisi"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('Person V1'),
      "icon": "feather icon-user",
      "lazy": true,
      "before-change": _vm.validationFormDriver
    }
  }, [_c('validation-observer', {
    ref: "driverRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Drivers "), _c('b-badge', {
    attrs: {
      "href": "#",
      "pill": "",
      "variant": _vm.statusSuggestionColor[_vm.dm_personalState]
    }
  }, [_c('strong', [_c('span', {
    domProps: {
      "textContent": _vm._s(_vm.statusSuggestion[_vm.dm_personalState])
    }
  })])])], 1), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(this.$t('Create Your ')) + "Drivers " + _vm._s(this.$t(' or Use data on already exist')) + ". ( Bisa dicari dengan "), _c('code', [_c('strong', [_vm._v("Nama Lengkap")])]), _vm._v(" ataupun dengan "), _c('code', [_c('strong', [_vm._v("Nomor KTP")])]), _vm._v(" ) ")])])], 1), _c('div', [_c('div', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Driver Name",
      "label-for": "vi-dm_personal-full_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Driver Name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_personal-full_name",
            "suggestions": [{
              data: _vm.form.dm_personal.dm_personals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Full Name'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_personal', 'full_name');
            },
            "input": function input($event) {
              return _vm.searchForm($event, 'dm_personal/', 'dm_personal', 'full_name');
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var suggestion = _ref15.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item",
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.full_name, ", KTP: ").concat(suggestion.item.no_ktp || '', ", HP: ").concat(suggestion.item.phone || '', " "))
                }
              })])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_personal.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_personal, "full_name", $$v);
            },
            expression: "form.dm_personal.full_name"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_personalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_personalState]))])])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.dm_personalStateLoading ? _c('b-spinner', {
          staticClass: "suggest-loading",
          attrs: {
            "small": "",
            "variant": "primary"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No KTP",
      "label-for": "cost"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "No KTP",
      "rules": {
        required: true,
        integer: true,
        min: 16
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "cost",
            "raw": true,
            "options": _vm.options.noKTP,
            "readonly": _vm.$router.currentRoute.params.id !== undefined ? false : !_vm.form.dm_personal.isNew,
            "placeholder": "otomatis terisi"
          },
          model: {
            value: _vm.form.dm_personal.no_ktp,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_personal, "no_ktp", $$v);
            },
            expression: "form.dm_personal.no_ktp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No HP",
      "label-for": "quantity"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "No HP",
      "rules": {
        required: true,
        min: 10,
        max: 13
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "quantity",
            "type": "number",
            "placeholder": "otomatis terisi",
            "readonly": _vm.$router.currentRoute.params.id !== undefined ? false : !_vm.form.dm_personal.isNew
          },
          model: {
            value: _vm.form.dm_personal.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_personal, "phone", $$v);
            },
            expression: "form.dm_personal.phone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone of Created BY",
      "label-for": "vi-dm_personal-phone_of_created_by"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-dm_personal-phone_of_created_by",
      "placeholder": "Otomatis Terisi",
      "value": _vm.form.dm_personal.user.phone,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Created BY",
      "label-for": "created_by"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "created_by",
      "type": "text",
      "readonly": "",
      "value": _vm.form.dm_personal.user.full_name,
      "placeholder": "otomatis terisi"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Created AT",
      "label-for": "created_at"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "created_at",
      "type": "text",
      "value": _vm.dateFormat(_vm.form.dm_personal.created_at),
      "readonly": "",
      "placeholder": "otomatis terisi"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Updated AT",
      "label-for": "updated_at"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "updated_at",
      "type": "text",
      "readonly": "",
      "value": _vm.dateFormat(_vm.form.dm_personal.updated_at),
      "placeholder": "otomatis terisi"
    }
  })], 1)], 1)], 1)], 1)], 1)])], 1)], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('Schedules_LH'),
      "icon": "feather icon-calendar",
      "lazy": true,
      "before-change": _vm.validationFormSchedule
    }
  }, [_c('validation-observer', {
    ref: "scheduleRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Schedules")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(this.$t('Create Your ')) + "Schedules")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Schedule IN",
      "label-for": "vi-schedule_in"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Schedule IN",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.schedule_in,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "schedule_in", $$v);
            },
            expression: "form.schedule.schedule_in"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Schedule OUT",
      "label-for": "vi-schedule_out"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Schedule OUT",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.schedule.schedule_out,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "schedule_out", $$v);
            },
            expression: "form.schedule.schedule_out"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }